/*****************************************************
** General internal page styles
**
** These styles should be general, and small in scope
**
** Consider giving in-depth style blocks their own
** partial file
*****************************************************/

.internal-image {
    margin-bottom: 1.5rem;
}

.main-content {
    line-height: 2;
    margin: 3rem 0;

    h1, h2, h3, h4, h5, h6 {
        line-height: 1.2;
    }
    h2 {
        margin-top: 0;
    }
    h3, h4 {
        margin-top: 3rem;
    }
}


/*****************************************************
**
** 768+
**
*****************************************************/
@include media-breakpoint-up(md) 
{
    .internal-image {
        float: right;
        max-width: 50%;
        margin-left: 1.5rem;
    }
}


/*****************************************************
**
** 992+
**
*****************************************************/
@include media-breakpoint-up(lg) 
{
    .int-content-wrapper {
        margin: 6rem 0;
    }
}


/*****************************************************
**
** 1200+
**
*****************************************************/
@include media-breakpoint-up(xl) 
{
    .main-content {
        margin: 5rem 0;
    }
}


/*****************************************************
**
** 1400+
**
*****************************************************/
@include media-breakpoint-up(xxl) 
{
    //
}
.manuItemsTwoCopyOne img {
    max-width: 50%;
}
.heroServ {
    display: flex;
    min-height: 66vh;
    width: 100%;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(https://dancoxinc.com/wp-content/themes/understrap-child/images/img/servicesHero.jpeg);
    background-color: rgba(116, 118, 134, 1);
    background-size: cover;
    background-blend-mode: luminosity;
    align-items: center;
}

.heroServCont {
    display: grid;
    width: 100%;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(6, 1fr);
    max-height: 30vh;
    img{
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 4;
    }
    h1 {
        padding-left: 8%;
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 2;
        align-self: end;
        justify-self: left;
        color: #FFFFFF;
        font-size: 4rem;
    }
    h3 {
        padding-left: 8%;
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 2;
        justify-self: left;
        align-self: center;
        color: #FFFFFF;
        font-size: 2rem;
    }
}

.featuredServicesSec {
    background: url(https://dancoxinc.com/wp-content/themes/understrap-child/images/vec/backgrounds/bckgrndGrey.svg);
    width: 100vw;
    background-size: cover;
    min-height: 80vh;
    display: flex;
    justify-content: center;
}

.servicesCont {
    margin-top: -8%;
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin-bottom: 10%;
}
.sI1{
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(https://dancoxinc.com/wp-content/themes/understrap-child/images/img/servicesCal.jpeg);
}
.sI2{
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(https://dancoxinc.com/wp-content/themes/understrap-child/images/img/servicesCal2.jpeg);
}
.sI3{
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(https://dancoxinc.com/wp-content/themes/understrap-child/images/img/servicesCal3.jpeg);
}
.sI4{
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(https://dancoxinc.com/wp-content/themes/understrap-child/images/img/servicesCal4.jpeg);
}
.sI5{
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(https://dancoxinc.com/wp-content/themes/understrap-child/images/img/servicesCal5.jpeg);
}
.servicesItem { 
    
    background-size: cover;
    background-blend-mode: darken;
    max-width: 430px;
    max-height: 375px;
    background-color: #013DA5;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: flex-start;
    border-radius: 8px;
    h3 {
        color: #ffffff;
        font-size: 2rem;
    }
    hr{
        min-width: 100%;
        margin-block-start: 0em;
        margin-block-end: 0em;
         margin: 1rem 0;
    background-color: #FFFFFF;
    border: 0;
    opacity: 1;
    }
    p {
        color: #ffffff;
        line-height: 24px;
    }
    button {
        color: #ffffff;
        justify-self: left;
        border: none;
        background: none;
        text-align: left;
        padding: 0%;
        font-family: "figtree", sans-serif;
        font-weight: 600;
        font-style: normal;
        text-transform: uppercase;
        margin-block-start: 0em;
        margin-block-end: 0em;
        font-size: 1.25rem;
    }
}

@media only screen and (max-width: 768px) {

.navbar{
padding-top: 2.5rem;
padding-bottom: 2.5rem;
    
}
    .manuItemsTwoCopyOne img{
        display: none;
    }
    .menuTag {
        display: none;
    }

    .heroServ {
        min-height: 40vh;
    }

    .heroServCont {
        max-height: 18vh;
        grid-template-columns: repeat(4, 1fr);
        img {
            margin-top: -3%;
            grid-row-start: 2;
            max-width: 40vw;
        }
        h1 {
            font-size: 1.75rem;
            padding-left: 0%;
            grid-column-start: 2;
        }
        h3 {
            padding-left: 0%;
            grid-column-start: 2;
            font-size: 1rem;
        }
    }

    .servicesCont {
        margin-top: -10%;
    }

    .servicesItem {
        max-width: 220px;
        max-height: 420px;
        padding: 32px;
        gap: 16px;
        h3 {
            font-size: 1.25rem;
        }
        p {
            font-size: .8rem;
            line-height: 16px;
        }
        hr{
        margin: 1rem 0px;
    background-color: #FFFFFF;
    border: 0;
    opacity: 1;

        }
    }
}

@media only screen and (max-width: 480px) {
    .heroServ {
        min-height: 50vh;
    }

    .heroServCont {
        display: flex;
        flex-direction: column;
        margin: 0% 10%;
        gap: 15px;
        img {
            display: none;
        }
        h1 {
            font-size: 2.25rem;
            align-self: flex-start;
        }
        h3 {
            font-size: 1.5rem;
            align-self: flex-start;
        }
    }

    .servicesCont {
        margin-top: -25%;
        margin-bottom: 25%;
    }

    .servicesItem {
        max-width: 80vw;
        gap: 15px;
    }

}