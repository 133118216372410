/*****************************************************
** Blog index styles
**
**
*****************************************************/

.post-index {
    margin-bottom: 4rem;
}


/*****************************************************
**
** 768+
**
*****************************************************/
@include media-breakpoint-up(md) 
{
    //
}


/*****************************************************
**
** 992+
**
*****************************************************/
@include media-breakpoint-up(lg) 
{
    //
}


/*****************************************************
**
** 1200+
**
*****************************************************/
@include media-breakpoint-up(xl) 
{
    //
}


/*****************************************************
**
** 1400+
**
*****************************************************/
@include media-breakpoint-up(xxl) 
{
    //
}