#scrollspy-sidebar {
    display: none;
    position: sticky;
    top: 115px;
    margin: 1rem 0;
    text-align: right;
    padding-right: 1.5rem;

    #scrollspy-sidebar-headers {

        .list-group-item {
            border: 0;
            padding: 0;
            margin: 0 0 1.5rem;
            background: none;
            color: $secondary;
            text-transform: uppercase;
            font-size: 1.5rem;
            font-weight: bold;
            transition: width ease .6s;

            &:after {
                content: '';
                height: 2px;
                background: $secondary;
                width: 0%;
                display: block;
                transition: width ease .6s;
                margin: 0 0 0 auto;
            }
            &:hover, &:focus {
                background: none;

                &:after {
                    width: 100%;
                }
            }
        }
        .list-group-item.active {
            color: white;

            &:after {
                width: 100%;
                background: white;
            }
        }
    } 
}

@include media-breakpoint-up(lg) {
    #scrollspy-sidebar {
        display: block;
    }
    .scrollspy-sidebar-wrapper {
        border-right: 1px solid #707070;
    }
}

@include media-breakpoint-up(xxl) 
{
    #scrollspy-sidebar {
        padding-right: 3rem;
    }
}