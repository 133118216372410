/*****************************************************
** For global component mobilebar
**
** Description
*****************************************************/

.callout-mobilebar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	z-index: $zindex-modal;
	background: $light;
	box-shadow: 0 0 10px rgba(0, 0, 0, .2);

	.callout-mobilebar-col {
		padding: .5rem;
		border-right: 1px solid rgba(0, 0, 0, .2);

		&:last-child {
			border-right: 0;
		}
	}
}

@include media-breakpoint-down(md) {
    body, .offcanvas {
        margin-bottom: 40px;
    }
}


/*****************************************************
**
** 768+
**
*****************************************************/
@include media-breakpoint-up(md) 
{
    //
}


/*****************************************************
**
** 992+
**
*****************************************************/
@include media-breakpoint-up(lg) 
{
    // 
}


/*****************************************************
**
** 1200+
**
*****************************************************/
@include media-breakpoint-up(xl) 
{
    //
}


/*****************************************************
**
** 1400+
**
*****************************************************/
@include media-breakpoint-up(xxl) 
{
    //
}


/*****************************************************
**
** 1600+
**
*****************************************************/
@media all and (min-width: 1600px)
{
    //
}