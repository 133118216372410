/***************** 
** BASE
*****************/

.footer-type1 {
    background: #013da5;
    padding: 2rem .5rem;
    color: white;
    font-size: .75rem;
    margin-top: -1px;

    .footer-sm {
        margin-bottom: 2rem;

        a {
            font-size: 34px;
        }
    }
    .footer-byline {

        p {
            margin: 0;
        }
    }
}

/***************** 
** BREAKPOINTS 
*****************/

@media all and (min-width: 576px) {}
@media all and (min-width: 768px) {
.footer-type1 {

    .footer-sm {
        margin-bottom: 0;
    }
    .footer-byline {
        text-align: right;
    }
}
}
@media all and (min-width: 992px) {}
@media all and (min-width: 1200px) {}
@media all and (min-width: 1400px) {} 