/*****************************************************
** General Homepage Styling
**
** If you're working on a complex module consider creating 
** a new partial for it
*****************************************************/
.subMenuFoot{
    gap: 40px;
    flex-direction: column;
        gap: 5px!important;

}
.modalLeft{
        max-width: 95%;
}
.ctaSec{
        overflow: hidden;
}
.featuredSecTwo{
        height: 80vh;
    }
    .featuredSecCopy h1{
    z-index:3;
    }
    .subHeroLineTwo{
    z-index:1;
    }

    .ctaLogo{
        display: none;
    }
    .ctaCopy{
        margin-bottom:0;
    }
    .ctaBGImg{
        background-image: url(https://dancoxinc.com/wp-content/themes/understrap-child/images/img/placeholdercta.png);
    opacity: 0.5;
        min-height: 100%;
    height: 36vw;
}
.ctaBGImg img{
    display:none
}
.serveCircTwo img{
    display:none;

}
.serveCircThree{
        border-radius: 0%;

}
.serveCircContent h5 button{
            font-size: 1.25rem;
}
.serveLeftCont h5{
        font-size: .9rem;
}
.manuCont{
    width: 95%;
}
.radioSel{
       grid-column-start: 1;
}
/*****************************************************
**
** 768+
**
*****************************************************/
@include media-breakpoint-up(md) 
{
    //
    .serveLeftCont h5{
           font-size: inherit;
}
    .serveCircTwo img{
        display: inherit;
    
}
.serveCircThree{
        border-radius: 50%;

}
       .subHeroLeft{
            min-width: 300px;
    }
    .subHeroRight{
    min-width: 300px;
    }
    .heroOne{
    padding: 0 65px!important;
    }
    .serveCircContent h5 button{
            font-size: 15px;
    }
    .accordion-body p {
    font-size: 12px;
    }
    .serveCircThree{
        padding-bottom: 60px;
    }
    .featuredSecTwo{
        height: 125vw;
    }
    .ctaLogo{
        display: block;
    }
    .ctaBGImg img{
    display:block
}
.ctaBGImg{
        background-image: none;
    opacity: 1;
        min-height:auto;
    height:auto;
}
.typeItemDisplayTwo{
        height: 150px;
}

}


/*****************************************************
**
** 992+
**
*****************************************************/
@include media-breakpoint-up(lg) 
{
    // 
   
    
.featuredSecTwo{
        height: 85vw;
    }
    .serveLeftCont h5{
            font-size: 1rem;
     }
    header{
    padding: 60px 30px;
    }
    .modalLeft{
        max-width: 25%;
}

.manuCont{
    width: 80vw;
}
}


/*****************************************************
**
** 1200+
**
*****************************************************/
@include media-breakpoint-up(xl) 
{
 .radioSel{
       grid-column-start: 2;
}
    //
    header{
    padding: 60px 30px;
    }
    .typeItemDisplayTwo{
        height: 180px;
}
     .serveLeftCont h5{
            font-size: 1.5rem;
     }
    .serveCircContent h5 button{
            font-size: 1rem;
    }
    .accordion-body p {
    font-size: 17px;
    }

        .serveCircThree{
        padding: 37px 0;
        }

        .featuredSecTwo{
        height: 90vw;
    }
    .serveRightCont{
            min-width: 45vw;
    }
}


/*****************************************************
**
** 1400+
**
*****************************************************/
@include media-breakpoint-up(xxl) 
{
    //
    header{
    padding: 60px 30px;
    }
    .serveCircContent h5 button{
            font-size: 2rem;
    }
    .subMenuFoot{
            flex-direction: row;
                    gap: 20px!important;
            a{
                font-size: 1rem!important;
            }
    }
    .subMenu{
     margin-right: -16%;
    }
    .featuredSecTwo{
        height: 62vw;
    }
    .serveRightCont{
            min-width: 50vw;
    }
}


/*****************************************************
**
** 1600+
**
*****************************************************/
@media all and (min-width: 1600px)
{
    //
header{
    padding: 64px 116px;
    }

    .featuredSecTwo{
                height: 50vw;
    }
    .serveRightCont{
            min-width: 45vw;
    }
}
.mega-menu-link{
color: #707070;
font-family: figtree, sans-serif;
font-weight: 700;
}

#myBtn h4{
margin-block-start: .5em;
}

#myBtn{
    h4{
    font-size: 1rem;
text-align: center;
    }
}
.Nav-color{
    color:#013DA5!important;
}
body { 
    min-height: 100vh; 
    max-width: 100vw; 
    background-color: #FFFFFF;
    margin: 0 auto; 
}

        /* MODAL */
            .modal {
                display: none; /* Hidden by default */
                position: fixed; /* Stay in place */
                z-index: 10; /* Sit on top */
                left: 0;
                top: 0;
                width: 100%; /* Full width */
                height: 100%; /* Full height */
                overflow: auto; /* Enable scroll if needed */
                background-color: rgb(0,0,0); /* Fallback color */
                background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
            }
            
            /* Modal Content/Box */
            .modal-content {
                background-color: rgba(0, 0, 0, 0.4);
                margin: 2% auto;
                padding: 40px;
                color: #ffffff;
                border: 1px solid #FFFFFF;
                backdrop-filter: blur(35px);
                border-radius: 8px;
                width: 80vw;
            }

            .modalCont {
                display: flex;
                justify-content: space-between;
            }

            .modalLeft {
                display: flex;
                flex-direction: column;
              
                gap: 24px;
                hr {
                    min-width: 100%;
                }
            }

            .compInfo {
                display: flex;
                justify-content: space-between;
            }

            .iconModal {
                background-color: #013DA5;
                border-radius: 50%;
                aspect-ratio: 1 / 1;
            }

            .mapInfo {
                border-radius: 8px;
                max-width: 100%;
                max-height: 160px;
                overflow: hidden;
                object-fit: contain;
                display: flex
            ;
                align-items: center;
                img {
                border-radius: 8px;
                max-width: 100%;
                }
            }

            .areaInfo {
                display: flex;
                    flex-direction: column;
                    gap: 16px;
                    align-items: center;
                    background-color: #747686;
                    border-radius: 8px;
                    padding: 16px;
                    height: 100%;
                    justify-content: center;
                img {
                    width: 75%;
                }
            }

            .modalRight {
                width: 60%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 24px;
            }

            .contactForm {
                width: 100%;
                display: grid
            ;
                gap: 10px;
                align-content: center;
                justify-content: center;
                align-items: center;
                justify-items: stretch;
                grid-template-columns: .1fr 1fr;
                label {
                    font-family: "figtree", sans-serif;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                input {
                    border: none;
                    max-width: 100%;
                    float: right;
                    min-height: 32px;
                    border-radius:  22px;
                    background-color: rgba(255 255 255 / 25%);
                    margin-bottom: 8px;
                }
                hr {
                    grid-column-start: 1;
                    grid-column-end:3;
                    width: 100%;
                }
            }
            .formInfo {
                grid-column-start: 2;
                grid-column-end:3;
            }

            .radioSel {
              
                grid-column-end: 3;
                border: none;
            }

            .radioButtons {
                display: flex;
                align-items: center;
                align-content: center;
                flex-direction: row;
                gap: 16px;
                justify-content: space-evenly;
                input {
                    margin-right: -10%;
                }
            }
            
            #message {
                min-height: 10vh;
            }
            
            .uploadBtn {
                display: flex;
                background: linear-gradient(90deg, #747686, #B3B5C4, #747686);
                font-family: "figtree", sans-serif;
                font-weight: 800;
                font-size: 1rem;
                text-transform: uppercase;
                justify-self: center;
                grid-column-start: 1;
                grid-column-end: 3;
                border: none;
                min-width: 40%;
                margin-top: 0%;
                min-height: 48px;
                border-radius: 80px;
                margin-bottom: 0px;
                align-items: center;
                justify-content: center;
            }
            #upload {
                display: none;
            }

            #submit {
                color: #FFFFFF;
                font-family: "figtree", sans-serif;
                font-weight: 800;
                font-size: 2rem;
                
                text-transform: uppercase;
                justify-self: center;
                grid-column-start: 1;
                grid-column-end: 3;
                border: none;
                min-width: 50%;
                
                margin-top: 2%;
                min-height: 80px;
                border-radius: 80px;
                background-color: #013DA5;
                margin-bottom: 8px;
            }

            /* The Close Button */
            .close {
               
                color: #fff;
font-size: 18px;
font-weight: 700;
align-self: flex-end;
            }
            
            .close:hover,
            .close:focus {
                color: black;
                text-decoration: none;
                cursor: pointer;
            }

/*extrabold*/
h1, h2, h3, h4, h5, h6, a {
    font-family: "figtree", sans-serif;
    font-weight: 800;
    font-style: normal;
    text-transform: uppercase;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

a {
    text-decoration: none;
}

/*semibold*/
p {
    font-family: "figtree", sans-serif;
    font-weight: 600;
    font-style: normal;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.blBtn {
    font-family: "figtree", sans-serif;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    padding-block: 4%;
    padding-inline: 6%;
    border-radius: 60px;
    color: #FFFFFF;
    border: none;
    background-color: #013DA5;
    font-size: 1rem;
    filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.25));
    transition: .15s;
}

.grBtn {
    font-family: "figtree", sans-serif;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    padding-block: 4%;
    padding-inline: 3%;
    border-radius: 60px;
    color: #FFFFFF;
    border: none;
    background-color: #696a77;
    font-size: 1rem;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.25));
    transition: .15s;
}

.grBtnTwo {
    font-family: "figtree", sans-serif;
    font-weight: 800;
    font-style: normal;
    text-transform: uppercase;
    padding-block: 2%;
    padding-inline: 3%;
    border-radius: 60px;
    color: #FFFFFF;
    border: none;
    background-color: #696a77;
    font-size: 1.5rem;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.25));
    transition: .15s;
}

.blBtn:hover, .grBtn:hover, .grBtnTwo:hover {
    filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.5));
    transform: translateX(2px) translateY(2px);
    transition: .15s;
}

/* NAV */

header {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
   
    justify-content: space-between;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.5));
}

.menuOne {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
}

.subMenu {
    display: flex;
    flex-direction: column;

    gap: 12px;
}

.subMenu hr {
    margin-block-start: 0em;
    margin-block-end: 0em;
    border-style: none;
    background-color: #747686;
    height: 1px;
    width: 100%;
}

.subMenuOne {
    display: flex;
    gap: 40px;
    a {
        color: #707070;
        font-size: 1.25rem;
    }
}

.subMenuTwo {
    display: flex;
    justify-content: flex-end;
    color: #013DA5;
    gap: 32px;
}

.menuOneButton {
    display: flex;
        height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #013DA5;
    align-items: center;
    justify-content: center;
    color:#FFFFFF;
    font-size: .75rem;
    filter: drop-shadow(0 0 0.75rem rgba(0,0,0,0.5));
        padding: 25px;
}

.menuTag {
    align-self: center;
    justify-items: center;
    /* position: absolute; */
    font-size: 1.25rem;
    margin-bottom: -140px;
    color: #707070;
    background-color: #FFFFFF;
    padding: 4% 3% 1% 3%;
    border-radius: 8px;
    img {
        margin-right: -20px;
    }
}

/* HERO SECTION */
header{
        z-index: 10;
    position: relative;
}
hero {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;

}
.homeheros{
        z-index: 2;
    position: relative;
}
hero h2 {
    font-size: 2rem;
    color: #FFFFFF;
    line-height: 38px;
}

hero p {
    font-size: 1.125rem;
    color: #FFFFFF;
    line-height: 24px;
}

.heroOne {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    padding: 0px 302px;

        position: relative;
    z-index: -1;
}

.heroLeft {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    background: #444549;
    background-image: url(https://mdpmco.net/test/dancox/wp-content/themes/understrap-child/images/img/combustionHero.jpeg);
    background-position: center;
    background-size: cover;
    background-blend-mode: luminosity;
    transform: scalex(-1);
    z-index: -5;
}

.heroRight {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    background:#1f407a;
    background-image: url(https://mdpmco.net/test/dancox/wp-content/themes/understrap-child/images/img/electricalHero.jpeg);
    background-position: center;
    background-size: cover;
    background-blend-mode: luminosity;
    z-index:-5;
}

.subHeroLeft {
    grid-template-rows: 1fr;
    grid-column-start: 1;
    grid-column-end: 4;
    align-self: center;
    border-radius: 8px;
    border-style: solid;
    border-color: #FFFFFF;
    background-color: rgba(0, 0, 0, .5);;
    margin: -40px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    backdrop-filter: blur(10px);
    z-index:-3;
    }

.subHeroRight {
    grid-template-rows: 1fr;
    grid-column-start: 7;
    grid-column-end: 10;
    align-self: center;
    border-radius: 8px;
    border-style: solid;
    border-color: #FFFFFF;
    background-color: rgba(0, 0, 0, .5);;
    margin: -40px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    backdrop-filter: blur(10px);
    z-index:-3;
}

.subHeroLine {
    align-self: center;
    justify-self: center;
    grid-column-start: 5;
    min-height: 101%;
                max-width: unset;

}

.subHeroLineTwo {
    display: none;
}

.subHeroLineThr {
    display: none;
}

/* FEATURED SECTION */

.featuredSec {
    background: url(https://mdpmco.net/test/dancox/wp-content/themes/understrap-child/images/vec/backgrounds/bckgrndGrey.svg);
    width: 100vw;
    background-size: cover;
    min-height: 52vw;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        padding-left: 64px;
        font-size: 2.5rem;
        color:#013DA5;
    };
    h4 {
        font-size: 1rem;
        color:#013DA5;
        max-width: 75%;
    }
}

.featureCont {
    width: 72.708vw;
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding-bottom: 8vh;
    padding-bottom: 18vh;
}

.featureContItems {
    display: flex;
    gap: 8%;
    flex-wrap: wrap;
    justify-content: center;
}

.contItem {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 2%;
    margin-top: 2%;
    align-items: center;
}

.contImg {
    width: 260px;
    height: 260px;
    background-color: #FFFFFF;
    border-radius: 8px;
    border-style: solid;
    border-color:#013DA5;
}

/* WHO WE SERVE SECTION */

.serveSec {
    background: linear-gradient(#01286c, #FFFFFF);
    width: 100vw;
    background-size: auto;
    height: 63vw;
    align-content: center;
    position: relative;
    z-index: 4;
}

.serveCont {
    display: grid;
    max-height: 100vh;
    align-items: center;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    align-items: center;
}

.serveLeftCont {
    max-width: 50vw;
    max-height: 20vh;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(3, 1fr);
    z-index: 1;
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 6;
    img  {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 4;
        margin-top: -2%;
    };

    h1   {
        font-size: 2.5rem;
        color:#FFFFFF;
        grid-column-start: 3;
        grid-column-end: 7;
        grid-row-start: 1;
        align-self: end;
    };

    h5 {
        font-size: 1.5rem;
        color:#FFFFFF;
        grid-column-start: 3;
        grid-column-end: 7;
        grid-row-start: 3;
        align-self: start;
    };
}

.serveRightCont {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    min-width: 45vw;
    min-height: 100vh;
    z-index: 0;
    overflow: hidden;
    grid-column-start: 6;
    grid-column-end: 10;
    grid-row: 1;
}

.serveCircTwo {
    background-color: rgb(3 25 65 / 95%);
    overflow: hidden;
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 6;
    margin-right: -40%;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    mix-blend-mode: color-burn;
    img {
        height: 100%;
        width: 100%;
        mix-blend-mode: soft-light;
    }
}

.serveCircThree {
   
    background-blend-mode: normal;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 5;
    margin-left: 4%;
    
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    aspect-ratio: 1 / 1;
}

.serveCircFull {
    display: flex;
    gap: 24px;
    margin-left: 25%;
    svg {
        fill: #FFFFFF;
        margin-top: 8px;
    }
}

.serveCircContent {
    opacity: .8;
    max-width: 20vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h5 {
        font-size: 2rem;
        color:#FFFFFF;

        button{
        
        color:#FFFFFF;
            background: none;
    border: none;
    float: left;
    text-align: left;
        Font-weight: 700;
Text-transform: uppercase;
        }
    };

    hr {
        display: none;
        
    };

    p{
    color: white;
    }

}

.serveCircContentActive {
    max-width: 20vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h5 {
        font-size: 2rem;
        color:#FFFFFF;
    };
    hr {
        display:flex;
        border-style: none;
        height: 1px;
        width: 100%;
        background: #ffffff;
    };

    p {
        display: flex;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color:#FFFFFF;
    }
}

/* CTA SECTION */

.ctaSec {
    background-color: #707070;
    min-width: 100vw;
    background-size: auto;
    max-height: 66.66vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ctaLogo {
    position: absolute;
    opacity: .3;
    margin-left: -50%;
}

.ctaCont {
    overflow: hidden;
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;
    justify-items: center;
    background: radial-gradient(#01286c, #a6a6a6);
    background-blend-mode: multiply;
}

.ctaCopy {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    margin-bottom: -10%;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    gap: 32px;
    z-index: 5;
    text-align: center;
    h1 {
        font-size: 50px;
    };
    h4 {
        text-align: center;
        font-size: 22px;
    }
}
/*
.ctaLayover {
    height: 100vh;
    width: 100vw;
    background: radial-gradient(#013DA5, #FFFFFF);
    mix-blend-mode: color-burn;
}*/

.ctaBGImg {
    max-height: 66.66vh;
    grid-row-start: 1;
    grid-row-end: 4;
    object-fit: cover;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 4;
    img {
        width: 100%;
        height: auto;
        mix-blend-mode: multiply;
    }
}

/* FEATURED MANF SECTION */

.featuredSecTwo {
    background: linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(https://mdpmco.net/test/dancox/wp-content/themes/understrap-child/images/vec/backgrounds/bckgrndBlue.svg);
    width: 100vw;
    background-size: cover;
    
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        padding-left: 64px;
        font-size: 2.5rem;
        color: #747686;
    };
    h4 {
        font-size: 1rem;
        color:#747686;
        max-width: 75%;
    };
}

.featuredSecCont {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.featuredSecCopy {
    width: 72.708vw;
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.featuredManList {
    display: flex;
    width: 100%;
    padding-top: 80px;
    align-items: center;
    justify-content: space-between;
}

.manuItem {
    background-color: #FFFFFF;
    height: 160px;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.5));
}

.arrow {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: #747686;
    height: 40px;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
}

#aRight {
    transform: scale(-1,1);
}

/* FOOTER CTA */

.footerCTA {
    background: linear-gradient(rgba(1,61,165,.5), rgba(1,61,165,.5)), url(https://mdpmco.net/test/dancox/wp-content/themes/understrap-child/images/img/placeholderfooter.jpeg);
    background-size: cover;
    background-position: center;
    min-height: 33.33vh;
    display: flex;
    justify-content: center;
}

.footerCTACont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        color:#FFFFFF;
        margin-top: 4%;
        font-size: 1.5rem;
    };
    img {
        margin-top: -4%;
        max-width: 100%;
    } 
}

/* FOOTER */

footer {
    background-color: #747686;
    min-height: 33.33vh;
    display: flex;
    align-content: center;
    justify-content: center;
}

.footerCont {
    display: flex
;
    width: 80vw;
    align-items: center;
    justify-content: space-between;
}

.footerLeft {
    display: flex;
    align-items: center;
    gap: 40px;
}

.footerImg {
    height: 180px;
}

.footerInfo {
    color:#FFFFFF;
}

.subMenuFoot {
    display: flex;
    gap: 40px;
}

.subMenuFoot a {
    color:#FFFFFF; 
    font-size: 1.25rem;
}
@media only screen and (max-width: 768px) { 
.modal-content{
padding: 20px;
width: 90vw;

}
.navbar{
 padding-top: 2.5rem;
 padding-bottom: 2.5rem;
 } 
.formInfo p{
     font-size: .68rem;
        line-height: .8rem;
}
.radioButtons input{
    margin-right: 0%;
margin-bottom: 0px;
}
    .blBtn{
    font-size: .80rem;
    }

    .grBtn{
    font-size: .80rem;
    }
    header {
        padding: 25px 33px;
    }

    .grBtnTwo {
   
    }

    .menuOne img {
        max-width: 140px;
    }

    .menuOneButton {
        font-size: .64rem;
        text-align: center;
        height: 64px;
        width: 64px;
    }

    .subMenu {
        margin-right: 0%;
        gap: 4px;
    }

    .subMenuOne {
        gap: 8px;
        a {
            color: #707070;
            font-size: .6rem;
        }
    }

    .subMenuTwo {
        font-size: .6rem;
    }

    .menuTag {
        font-size: .75rem;
        margin-bottom: -64px;
        img {
            margin-left: 40px;
            max-width: 50%;
        }
    }

    hero h2 {
        font-size: 1.25rem;
        color: #FFFFFF;
        line-height: 24px;
    }

    hero {
        grid-template-rows: 64vh;
        h2 {
            font-size: 1.25rem;
            color: #FFFFFF;
            line-height: 24px;
            text-align: center;
        }
        p {
            font-size: 1rem;
            line-height: 24px;
        }
    }

    .heroOne {
        padding: 0px 88px
    }   

    .subHeroLeft, .subHeroRight {
        padding: 24px;
    }

    .subHeroLine { 
        display: none;
    }

    .subHeroLineTwo {
        display: flex;
        align-self: center;
        justify-self: center;
        grid-column-start: 5;
        min-height: 100%;
                max-width: unset;
    }

    .subHeroLineThr {
        display: none;
    }

    .featuredSec {
        min-height: 70vh;
        h1 {
            padding-left: 24px;
            font-size: 1.25rem;
        }
        h4 {
            font-size: 0.8rem;
        }
    }

    .featureCont {
        width: 80vw;
        margin-top: 6%;
        padding-bottom: 12vh;
    }

    .featureContItems {
        gap: 2%;
    }

    .contItem {
        max-width: min-content;
    }

    .contImg {
        width: 140px;
        height: 140px;
    }

    .serveLeftCont {
        max-width: 48vw;
        h1 {
            font-size: 1.5rem;
            grid-column-start: 2;
        }
        h5 {
            font-size: 1rem;
            grid-column-start: 2;
        }
        img {
            max-width: 100%;
            margin-top: 0%;
        }
    }

    .serveSec {
        height: 35pc;
    }

    .serveCont {
        max-height: 40vh;
    }

    .serveRightCont {
        min-width: 50vw;
        min-height: 40vh;

    }

    .serveCircFull {
        margin-left: 16%;
        gap: 12px;
        svg {
            margin-top: 0px;
        }
    }

    .serveCircTwo {
        background-color: #013DA5;
        overflow: hidden;
        grid-column-start: 1;
        grid-column-end: 6;
        grid-row-start: 1;
        grid-row-end: 6;
        margin-right: -40%;
        margin-top: 10%;
    }

    .serveCircThree {
        margin-left: 0%;
        gap: 8px;
        grid-column-end: 6;
        grid-row-start: 2;
    }

    .serveCircContentActive {
        max-width: 40vw;
        h5 {
            font-size: 1.125rem;
        }
        p {
            font-size: .625rem;
            line-height: 1rem;
        }
    }

    .serveCircContent {
        max-width: 40vw;
        h5 {
            font-size: 1rem;
            button{
        font-size: 1rem;
        }
        }
        
    }

    .ctaSec {
        max-height: 40vh;
    }

    .ctaBGImg {
        max-height: 40vh;
    }

    .ctaCopy {
        gap: 16px;
        width: 64vw;
        h1 {
            font-size: 1.5rem;
        }
        h4 {
            font-size: 1rem;
        }
    }

    .ctaLogo {
        max-width: 30%;
    }

    .featuredSecTwo {
    
    }

    .arrow {
        height: 24px;
        img {
            max-height: 40%;
        }
    }

    .featuredSecCopy {
        width: 80vw;
        h1 {
            padding-left: 24px;
            font-size: 1.25rem;
        }
    }

    .featuredManList {
        padding-top: 40px;
    }

    .manuItem {
        height: 80px;
    }

    .footerCTA {
    
    }

    .footerCTACont {
        h1 {
            font-size: 1rem;
        }
        img {
            max-width: 90%;
            margin-top: -4%;
                opacity: .3;
        }
    }

    footer {
        min-height: 12vh;
    }

    .footerCont {
        width: 88vw;
    }

    .footerLeft {
        gap: 16px;
    }

    .footerImg {
        height: 40px;
    }

    .footerInfo {
        p {
            font-size: .5rem;
        }
    }

    .subMenuFoot {
        gap: 16px;   
        a {
        color: #FFFFFF;
        font-size: .625rem;
        }
    }
    .serveCircFull svg{
        transform: scale(0.5);
    }
}

@media only screen and (max-width: 480px) {

    .subMenu {
        display: none;
    }

    .menuTag {
        font-size: 1rem;
        padding: 10% 5% 4% 5%;
        margin-bottom: -80px;
        img {
            margin-left: 20px;
            max-width: 80%;
        }
    }

    .grBtn {
        padding-block: 8%;
        padding-inline: 14%;
        text-align: left;
        font-size: .7rem;
        text-align: center;
    }

    .blBtn {
        padding-block: 8%;
        padding-inline: 14%;
        text-align: left;
        font-size: .8rem;
                text-align: center;
    }

    hero {
        h2 {
            font-size: 1.125rem;
            color: #FFFFFF;
            line-height: 20px;
            text-align: center;
        }
        p {
            font-size: 1rem;
            line-height: 18px;
        }
    }

    .heroOne {
        padding: 0px 0px;
    }

    .subHeroLeft {
        border-style: none;
        border-radius: 0px 10px 10px 0px;
        grid-column-end: 5;
        margin: 0px;
        padding: 20px 20px 20px 20px;
        gap: 10px;
    }

    .subHeroRight {
        border-style: none;
        border-radius: 10px 0px 0px 10px;
        grid-column-start: 6;
        grid-column-end: 10;
        margin: 0px;
        padding: 20px 20px 20px 20px;
        gap: 10px;
    }

    .subHeroLine {
        display: none;
    }
    
    .subHeroLineTwo {
        display: none;
    }
    
    .subHeroLineThr {
        display: flex;
        align-self: center;
        justify-self: center;
        grid-column-start: 5;
        min-height: 100%;
    }

    .featureCont {
        width: 80vw;
        margin-top: 20%;
        margin-bottom: 30%;
    }

    .featuredSec {
            min-height: 120vh;
        h1 {
            font-size: 1.75rem;
            padding-left: 10px;
        }
        h4 {
            font-size: 1rem;
            max-width: 80%;
        }
    }

    .featureContItems {
        gap: 2%;
        
    }

    .contItem {
        gap: 10px;
        margin-bottom: 4%;
    }

    .contImg {
        width: 160px;
        height: 160px;
    }

    .serveSec {
        height: 79vh;
        align-content: center;
        background: none;
        margin-top: -20%;
                overflow: hidden;
    }

    .serveCont {
        grid-template-rows: repeat(4, 1fr);
    }

    .serveLeftCont {
        grid-row: 1;
        grid-column-start: 1;
        grid-column-end: 10;
        max-width: 100vw;
        margin-top: -45%;
        h1 {
            font-size: 1.5rem;
            color: #FFFFFF;
            grid-column-start: 2;
            grid-column-end: 7;
            grid-row-start: 1;
            align-self: center;
        }
        h5 {
            grid-row-start: 2;
            grid-column-end: 8;
            font-size: 10px;

        }
        img {
            display: none;
        }
    }

    .serveRightCont {
        grid-row-start: 1;
        grid-row-end:4;
        grid-column-start: 1;
    grid-column-end: 10;
    grid-template-rows: repeat(4, 1fr);
    }

    .serveCircTwo {
        margin-left: -50%;
        margin-right: -50%;
     
        margin-top: 10%;
        grid-row-start: 1;
    }

    .serveCircThree {
        gap: 15px;
        grid-row-start: 2;
        grid-row-end: 5;
        margin: -35%;
        align-items: flex-start;
        padding: 65% 43%;
    }

        .serveCircFull {
            margin-left: 0%;
            gap: 12px;
            svg {
                display: none;
                transform: scale(0.5);
            }
        }
    

    .serveCircContentActive {
        max-width: 75vw;
    }

    .serveCircContent {
        max-width: 100vw;
    }

    .ctaSec {
        min-height: 60vh;
        max-height: 61vh;
    }

    .ctaBGImg {
        min-height: 60vh;
        max-height: 61vh;
        img {
            width: auto;
            height: 100%;
            mix-blend-mode: multiply;
        }
    }

    .ctaCopy {
        margin-bottom: 0%;
        width: 80vw;
    }

    .ctaLogo {
        max-width: 70%;
        margin-left: 0%;
        margin-bottom: 30%;
    }

    .featuredSec {
        h1 {
            padding-left: 10px;
        }
    }

    .featuredSecCont {
        width: 85vw;
    }

    .featuredSecCopy {
        width: 85vw;
    }

    .manuItem {
        height: 90px;
    }

    .manuItem:nth-child(4),
    .manuItem:nth-child(5),
    .manuItem:nth-child(6) {
        display: none;
    }

    .footerCTA {
        min-height: 22vh;
    }

    .footerCTACont {
        max-width: 85vw;
        h1 {
            max-width: 60vw;
            text-align: center;
            font-size: 1rem;
        }
        img {
            max-width: 100%;
            margin-top: -3%;
        }
    }

    .footerCont {
        display: flex
    ;
        width: 80vw;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 40px;
        margin-bottom: 15%;
    }

    .footerLeft {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: -15%;
        gap: 40px;
    }

    .footerImg {
        height: 200px;
        z-index: 2;
    }

    .footerInfo {
        p {
            font-size: 1rem;
        }
    }

    .subMenuFoot {
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: center;
        a {
            color: #FFFFFF;
            font-size: 1.25rem;
        }
    }

    .grBtnTwo {
        font-size: 1rem;
        padding-block: 6%;
        padding-inline: 10%;
    }

}