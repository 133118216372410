/*****************************************************
** Title
**
** Description
*****************************************************/

// Additional container sizing classes
//
.container-maxwrap {
    width: 92%;
    max-width: 1740px!important;
    margin: 0 auto;
}

.container-smwrap {
    max-width: 920px;
    margin: 0 auto;
}

// Content wrapper classes
//
.xxs-wrapper {
    max-width: 430px;
}

.xs-wrapper {
    max-width: 530px;
}

.sm-wrapper {
    max-width: 800px;
}

.md-wrapper {
    max-width: 1000px;
}

.lg-wrapper {
    max-width: 1200px;
}


// Padding removal utilities for containers
// 
@include media-breakpoint-down(sm) {
    .prm-sm {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}
@include media-breakpoint-down(md) {
    .prm-md {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}

@include media-breakpoint-down(lg) {
    .prm-lg {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}
@include media-breakpoint-down(xl) {
    .prm-xl {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}
@include media-breakpoint-down(xxl) {
    .prm-xxl {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}


/*****************************************************
**
** 768+
**
*****************************************************/
@include media-breakpoint-up(md) 
{
    //
}


/*****************************************************
**
** 992+
**
*****************************************************/
@include media-breakpoint-up(lg) 
{
    //
}


/*****************************************************
**
** 1200+
**
*****************************************************/
@include media-breakpoint-up(xl) 
{
    //
}


/*****************************************************
**
** 1400+
**
*****************************************************/
@include media-breakpoint-up(xxl) 
{
    //
}


/*****************************************************
**
** 1600+
**
*****************************************************/
@media all and (min-width: 1600px) 
{
    .container-bigwrap {
        max-width: 1560px!important;
    }
}