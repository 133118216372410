/*****************************************************
** General styles
**
** These styles should be general, and small in scope
**
** Consider giving in-depth style blocks their own
** partial file
*****************************************************/

// Turn off smooth scroll as it does not work properly
//
:root {
    scroll-behavior: auto;
}

// Hide Google's ReCaptcha badge
//
.grecaptcha-badge { 
    display: none; 
}

// iframe max width fix
//
iframe {
    max-width: 100%;
}
.heroProTypCont{
        grid-template-columns: auto;
}
.heroManuCont{
        display: block;
}
.heroServImg{
    display: none;
}
.blurItem{
        min-height: 0vw;
    }
    .heroManuCont{
            max-height: 100%;
    }
    .blurManuItem{
       min-height: 450px;;
    }
            .heroManuCont h3{
                    line-height: 1rem;
            }
/*****************************************************
**
** 768+
**
*****************************************************/
@include media-breakpoint-up(md) 
{
    //
    .blurItem{
         min-height: 450px;;
    }
     .blurManuItem{
    min-height: 0vw;
    }
     .heroManuCont h3{
                    line-height: 1.5rem;
            }
}


/*****************************************************
**
** 992+
**
*****************************************************/
@include media-breakpoint-up(lg) 
{
    //
    .blurItem{
        min-height: 0vw;
    }
    .blurManuItem{
    min-height: 0vw;
    }
    
}


/*****************************************************
**
** 1200+
**
*****************************************************/
@include media-breakpoint-up(xl) 
{
    //
.heroProTypCont{
     grid-template-columns: repeat(6, 1fr);
     }
     .heroManuCont{
        display: block;
                max-width: 75%;
        margin: 0 auto;
}
.heroServImg{
    display: grid;
}
 .blurItem{
        min-height: 0vw;
    }
    .blurManuItem{
    min-height: 0vw;
    }
}


/*****************************************************
**
** 1400+
**
*****************************************************/
@include media-breakpoint-up(xxl) 
{
    //
    .blurManuItem{
        min-height: 0vw;
    }
    
}
.myBtn{
    h4{
    font-size: 12px;
    }
}
.modal-content {
    background-color: rgba(0, 0, 0, 0.4);
    margin: 2% auto;
    padding: 64px;
    color: #ffffff;
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(35px);
    border-radius: 8px;
    width: 64vw;
}
.close {
    color: #FFFFFF;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
.modalCont {
    display: flex
;
    justify-content: space-between;
}

.modalLeft {
    display: flex
;
    flex-direction: column;
    max-width: 25%;
    gap: 24px;
    hr {
        min-width: 100%;
    }
}

.compInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
        padding-left: 16px;
    }
}

.iconModal {
    padding: 8px;
    background-color: #013DA5;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    
        min-height: 16px;
        max-height: 24px;
    
}

.mapInfo {
    border-radius: 8px;
    max-width: 100%;
    max-height: 160px;
    overflow: hidden;
    object-fit: contain;
    display: flex
;
    align-items: center;
    img {
        border-radius: 8px;
        max-width: 100%;
    }
}

.areaInfo {
    display: flex
;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    background-color: #747686;
    border-radius: 8px;
    padding: 16px;
    height: 100%;
    justify-content: center;
    img {
        width: 75%;
    }
}

.modalRight {
    width: 60%;
    display: flex
;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.contactForm {
    width: 100%;
    display: grid
;
    gap: 10px;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: stretch;
    grid-template-columns: .1fr 1fr;
    label {
        font-family: "figtree", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
    }
    input {
        border: none;
        max-width: 100%;
        float: right;
        min-height: 32px;
        border-radius: 22px;
        background-color: rgba(255 255 255 / 25%);
        margin-bottom: 8px;
        color: #ffffff;
        text-indent: 8px;
    }
    hr {
        grid-column-start: 1;
        grid-column-end: 3;
        width: 100%;
    }
}

.formInfo {
    grid-column-start: 2;
    grid-column-end: 3;
}

.radioSel {
    grid-column-start: 2;
    grid-column-end: 3;
    border: none;
}

.radioButtons {
    display: flex
;
    align-items: center;
    align-content: center;
    flex-direction: row;
    gap: 16px;
    justify-content: space-evenly;
}

#message {
    min-height: 10vh;
}

.uploadBtn {
    display: flex
;
    background: linear-gradient(90deg, #747686, #B3B5C4, #747686);
    font-family: "figtree", sans-serif;
    font-weight: 800;
    font-size: 1rem;
    text-transform: uppercase;
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 3;
    border: none;
    min-width: 40%;
    margin-top: 0%;
    min-height: 48px;
    border-radius: 80px;
    margin-bottom: 0px;
    align-items: center;
    justify-content: center;
}


#submit {
    color: #FFFFFF;
    font-family: "figtree", sans-serif;
    font-weight: 800;
    font-size: 2rem;
    text-transform: uppercase;
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 3;
    border: none;
    min-width: 50%;
    margin-top: 2%;
    min-height: 80px;
    border-radius: 80px;
    background-color: #013DA5;
    margin-bottom: 8px;
}

@media only screen and (max-width: 768px) {

    .modalCont {
        align-items: center;
    }

    .modal-content {
        padding: 40px;
        width: 80vw;
        margin: 8% auto;
        img {
            align-self: center;
            max-width: 100%;
        }
    }

    #modalDivider {
        max-height: 50vh;
    }

    .modalLeft {
        gap: 12px;
        max-width: 30%;
    }

    .compInfo {
        p {
            font-size: .68rem;
            margin-left: 0px;
        }
        img {
            align-self: center;
            min-width: 16px;
        }
    }

    .mapInfo {
        min-height: 100px;
    }

    .areaInfo {
        padding: 8px;
        height: 24vh;
        h3 {
            font-size: 1rem;
            text-align: center;
        }
    }

    .modalRight {
        width: 60%;
    }

    .contactForm {
        width: 100%;
        align-items: baseline;
        justify-content: start;
        grid-template-columns: 20% 80%;
        label {
            font-size: .8rem;
        }

        input {
            border: none;
            max-width: 100%;
            float: right;
            min-height: 28px;
            border-radius: 22px;
            background-color: rgba(255 255 255 / 25%);
            margin-bottom: 4px;
            color: #ffffff;
            text-indent: 8px;
        }
        p {
            font-size: .8rem;
            align-self: center;
        }
    }

    .formInfo {
        grid-column-start: 1;
        p {
            font-size: .68rem;
            line-height: 1rem;
        }
    }
    .radioSel {
        padding-block: 0;
    padding-inline: 0;
    }

    .radioButtons {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        label {
            align-self: center;
            font-size: .65rem;
        }
        input {
            margin-right: 0px;
        }
    }

    .uploadBtn {
        min-width: 80%;
    }

    #message {
        min-height: 6vh;
    }

    #submit {
        font-size: 1.5rem;
        min-width: 80%;
        margin-top: 2%;
        min-height: 64px;
    }
}

@media only screen and (max-width: 480px) {

    .contactForm {
        grid-template-columns: 20% 77%;
    }

    .modal-content {
        padding: 20px;
        width: 80vw;
        margin: 8% auto;
    }

    .modalCont {
        flex-direction: column;
    }

    .modalLeft {
        max-width: 100%;
        gap: 10px;
    }
    
    .compInfo {
        align-items: center;
        justify-content: flex-start;
        p {
            font-size: 1rem;
        }
        img {
           min-width: 12px;
        }
    }

    .iconModal {
        padding: 3%;
    }

    .mapInfo {
        max-height: 80px;
    }

    .areaInfo {
        height: 20vh;
        flex-direction: row;
    }

    #modalDivider {
        max-height: 30vh;
        transform: rotate(90deg);
        margin-top: -24%;
        margin-bottom: -24%;
        display: none;
    }

    .modalRight {
        margin-top: 10%;
        width: 100%;
        h1 {
            font-size: 1.5rem;
        }
    }

}

.menuTag {
    display: none;
}

.breadcrumbs {
    display: flex;
    grid-column-start: 3;
    grid-row-start: 1;
    grid-column-end: 5;
    align-items: center;
    color: #FFFFFF;
    font-size: 1rem;
    justify-content: flex-start;
    text-transform: uppercase;
    margin-left: 8%;
    gap: 24px;
    h4 {
        padding: 10px 16px 8px;
        border-radius: 60px;
        background-color: #013DA5;
    }
}

.blurItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
   
    min-width: 100%;
 
}

.heroProTyp {
    display: flex;
   padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(https://dancoxinc.com/wp-content/themes/understrap-child/images/img/heroProTyp.png);
    background-color: rgba(116, 118, 134, 1);
    background-size: cover;
    background-position: center;
    background-blend-mode: luminosity;
    align-items: center;
        z-index: -1;
    position: relative;
}

.heroProTypCont {
    display: block;
    max-width: 75%;
    margin: 0 auto;
    width: 100%;
    grid-template-rows: repeat(4, 1fr);
   
    img{
        margin-top: 3%;
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 5;
    }
    h1 {
        padding-left: 8%;
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 2;
       
        justify-self: left;
        color: #FFFFFF;
        font-size: 2rem;
    }
    h3 {
        padding-left: 8%;
        padding-right: 8%;
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 5;
        justify-self: left;
        align-self: top;
        color: #FFFFFF;
        font-size: 1rem;
        line-height: 1.5rem;
        text-transform: none;
    }
}

.featuredProTypSec {
    background: url(https://mdpmco.net/test/dancox/wp-content/themes/understrap-child/images/vec/backgrounds/bckgrndGrey.svg);
    width: 100vw;
    background-size: cover;
    display: flex;
    justify-content: center;
    h4 {
        font-size: 1rem;
        color: #013DA5;
        max-width: 75%;
    }
}

.servicesProTypCont {
    margin-top: 2.5%;
    margin-bottom: 5%;
    width: 72.708vw;
}

.featureProTypContItems {
    display: flex;
    gap: 8%;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 768px) { 

    .breadcrumbs {
        margin-left: 0%;
        grid-column-start: 2;
        grid-column-end: 4;
        h4 {
            padding: 8px 12px 8px;
            font-size: .75rem;
        }
    }
    
    .heroProTypCont {
       
        display: block;
        img {
            margin-top: -3%;
            grid-row-start: 3;
            max-width: 40vw;
        }
        h1 {
            padding-right: 8%;
            padding-left:8%;
            font-size: 1.75rem;
            padding-left: 0%;
            grid-column-start: 2;
            grid-column-end: 4;
        }
        h3 {
           padding-right: 8%;
            padding-left:8%!important;
            font-size: .8rem;
            line-height: 1.25rem;
            padding-left: 0%;
            grid-column-start: 2;
            grid-column-end: 4;
        }
    }

    .servicesProTypCont {
        margin-top: 5%;
        margin-bottom: 5%;
        width: 80vw;
    }

    .featureProTypContItems {
        justify-content: space-evenly;
        gap: 4%;
    }

    .featuredProTypSec {
        h4 {
            font-size: 1rem;
            color: #013DA5;
            max-width: 85%;
        }
    }

    .contImg {
        width: 160px;
        height: 160px;
    }
}

@media only screen and (max-width: 480px) { 

    .heroProTyp {
                min-height: 530px;
    }

    .heroProTypCont {
        display: flex;
        flex-direction: column;
     
        align-items: flex-start;
        margin: 0% 10%;
        gap: 15px;
        img {
            display: none;
        }
        h1 {
            font-size: 1.5rem;
            padding-left: 0%;
        }
        h3 {
            margin-top: 0%;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    .breadcrumbs {
        h4 {
            padding: 10px 16px 10px;
            font-size: 1rem;
        }
    }

    .servicesProTypCont {
        margin-top: 10%;
        margin-bottom: 10%;
        width: 80vw;
    }

    .featureProTypContItems {
      
    }
} 

.menuTag {
    display: none;
}

.blurTypeItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 56vh;
    min-width: 100%;
    backdrop-filter: blur(10px);
    margin-bottom: -80px;
}

.heroType {
    display: flex;
    min-height: 56vh;
    width: 100%;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(https://mdpmco.net/test/dancox/wp-content/themes/understrap-child/images/img/electricalHero.jpeg);
    background-color: rgba(116, 118, 134, 1);
    background-size: cover;
    background-position: center;
    background-blend-mode: luminosity;
    align-items: center;
    flex-direction: column;
}

.heroTypeCont {
    display: grid;
    width: 100%;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(6, 1fr);
    max-height: 36vh;
    img{
        margin-top: 1%;
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 5;
    }
    h1 {
        padding-left: 8%;
        grid-column-start: 2;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 3;
        align-self: end;
        justify-self: left;
        color: #FFFFFF;
        font-size: 2rem;
    }
    h3 {
        padding-left: 8%;
        grid-column-start: 2;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 5;
        justify-self: left;
        align-self: top;
        color: #FFFFFF;
        font-size: 1.25rem;
        line-height: 1.5rem;
        text-transform: none;
    }
    p {
        padding-left: 8%;
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 5;
        grid-row-end: 6;
        justify-self: left;
        align-self: top;
        color: #FFFFFF;
        font-size: 1rem;
        line-height: 1.5rem;
        text-transform: none;
        padding-top: 2.5%;
    }
}

.featuredTypeSec {
    background: url(https://mdpmco.net/test/dancox/wp-content/themes/understrap-child/images/vec/backgrounds/bckgrndGrey.svg);
    width: 100vw;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
        font-size: 1rem;
        color: #013DA5;
        max-width: 75%;
    }
}

.typeDivider {
    display: flex;
    width: 80vw;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 5%;
}

.typeCont {
    display: flex;
    width: 36vw;
    gap: 16px;
    flex-direction: column;
    h1 {
        padding-left: 40px;
        font-size: 2.5rem;
        color: #013DA5;
    }
}

.featureTypeItems {
    display: flex;
    gap: 4%;
    flex-wrap: wrap;
    justify-content: center;
}

.typeItemDisplay {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 2%;
    margin-top: 2%;
    align-items: center;
}

.contTypeImg {
    width: 180px;
    height: 180px;
    background-color: #FFFFFF;
    border-radius: 8px;
    border-style: solid;
    border-color: #013DA5;
}

.typeContTwo {
        display: flex;
        width: 36vw;
       
        h1 {
            padding-left: 40px;
            font-size: 2.5rem;
            color: #747686;
        }
}

.featureTypeItemsTwo {
    display: flex;
    gap: 8%;
    flex-wrap: wrap;
    justify-content: center;
}

.typeItemDisplayTwo {
    background-color: #FFFFFF;
    height: 100%;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.25));
    margin-top: 2%;
    margin-bottom: 6%;
}
.typeItemDisplayTwo2 {
    height: 180px;
}
@media only screen and (max-width: 768px) {

    .heroType {
        min-height: 40vh;
    }

    .blurTypeItem {
        min-height: 40vh;
    }

    .heroTypeCont {
        max-height: 28vh;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        img {
            margin-top: -4%;
            grid-row-start: 2;
            max-width: 40vw;
        }
        h1 {
            font-size: 1.75rem;
            padding-left: 0%;
            grid-column-start: 2;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
        }
        h3 {
            font-size: 1rem;
            line-height: 1.25rem;
            padding-left: 0%;
            grid-column-start: 2;
            grid-column-end: 4;
            grid-row-start: 3;
        grid-row-end: 4;
        }
        p {
            font-size: .8rem;
            line-height: 1rem;
            padding-left: 0%;
            grid-column-start: 2;
            grid-column-end: 4;
            grid-row-start: 4;
        grid-row-end: 5;
        padding-top: 2.5%;
        }
    }

    .typeDivider {
        flex-direction: column;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .typeCont {
        width: 80vw;
        h1 {
            font-size: 2rem;
            padding-left: 16px;
        }
    }

    .featureTypeItems {
        padding: 0px 16px;
        justify-content: space-between;
    }

    .contTypeImg {
        width: 160px;
        height: 160px;
    }

    .typeContTwo {
        width: 80vw;
        margin-top: 10%;
        h1 {
            font-size: 2rem;
            padding-left: 16px;
        }
    }

    .featureTypeItemsTwo {
        
    }
}


@media only screen and (max-width: 480px) {
    
    .heroType {
        min-height: 50vh;
    }

    .blurTypeItem {
        min-height: 50vh;
    }

    .typeDivider {
        margin-top: 20%;
        margin-bottom: 20%;
    }

    .heroTypeCont {
        max-height: 40vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0% 10%;
        gap: 10px;
        img {
            display: none;
        }
        h1 {
            font-size: 1.5rem;
        }
        h3 {
            font-size: 1rem;
            line-height: 1.5rem;
        }
        p {
            font-size: 1rem;
            line-height: 1.25rem;
            padding-top: 0%;
        }
    }

    .typeCont {
        h1 {
            font-size: 1.75rem;
            padding-left: 0px;
        }
    }

    .featureTypeItems {
        padding: 0px 0px;
        justify-content: space-between;
    }

    .typeItemDisplay {
        gap: 16px;
        margin-bottom: 6%;
    }

    .typeContTwo {
        h1 {
            font-size: 1.75rem;
            padding-left: 0px;
        }
    }

    .featureTypeItemsTwo {
        justify-content: space-between;
    }

    .typeItemDisplayTwo {
        height: 160px;
        margin-top: 2%;
        margin-bottom: 8%;
    }
}

.menuTag {
    display: none;
}

.breadcrumbs {
    display: flex;
    grid-column-start: 3;
    grid-row-start: 1;
    grid-column-end: 5;
    align-items: center;
    color: #FFFFFF;
    font-size: 1rem;
    justify-content: flex-start;
    text-transform: uppercase;
    margin-left: 8%;
    gap: 24px;
    h4 {
        padding: 10px 16px 8px;
        border-radius: 60px;
        background-color: #013DA5;
    }
}

.blurManuItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
 
    min-width: 100%;
    
    margin-bottom: -80px;
}

.heroManu {
    display: flex;
        padding: 10% 0;
    width: 100%;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(https://dancoxinc.com/wp-content/themes/understrap-child/images/img/electricalHero.png);
    background-color: rgba(116, 118, 134, 1);
    background-size: cover;
    background-position: center;
    background-blend-mode: luminosity;
    align-items: center;
    flex-direction: column;
        position: relative;
    z-index: 0;
}

.heroManuCont {
    
    width: 100%;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(6, 1fr);
    
    img{
        margin-top: 0%;
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 5;
    }
    h1 {
        padding-left: 8%;
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 3;
        align-self: end;
        justify-self: left;
        color: #FFFFFF;
        font-size: 2rem;
        padding-right: 8%;
    }
    h3 {
        padding-left: 8%;
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 4;
        grid-row-end: 5;
        justify-self: left;
        align-self: top;
        color: #FFFFFF;
        font-size: 1rem;
        line-height: 1.5rem;
        text-transform: none;
        padding-right: 8%;
    }
}

.companyIcon {
    background-color: #FFFFFF;
    min-width: 160px;
    min-height: 160px;
    margin-bottom: -80px;
    z-index: 1;
    border-radius: 50%;
    aspect-ratio: 1 /1;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.5));
    img {
        max-width: 120px;
    }
}

.featuredManuSec {
    background: url(https://mdpmco.net/test/dancox/wp-content/themes/understrap-child/images/vec/backgrounds/bckgrndGrey.svg);
    width: 100vw;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
        font-size: 1rem;
        color: #013DA5;
        max-width: 75%;
    }
}

.manuCont {
    display: flex;
    margin-top: 9%;
    margin-bottom: 2.5%;
    width: 72.708vw;
    gap: 16px;
    flex-direction: column;
    h1 {
        padding-left: 64px;
        font-size: 2.5rem;
        color: #013DA5;
    }
}

.featureManuItems {
    display: flex;
    gap: 4%;
    flex-wrap: wrap;
    justify-content: center;
}

.manuItemDisplay {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 2%;
    margin-top: 2%;
    align-items: center;
}

.contManuImg {
    width: 180px;
    height: 180px;
    background-color: #FFFFFF;
    border-radius: 8px;
    border-style: solid;
    border-color: #013DA5;
}

.manuContTwo {
    display: flex;
    margin-bottom: 5%;
    width: 72.708vw;
    gap: 16px;
    flex-direction: column;
    align-items: center;
}

.manuContTwoImg {
    max-width: 400px;
}

.featureManuItemsTwo {
    display: flex;
    flex-direction: column;
    max-width: 56vw;
    gap: 24px;
    align-items: center;
}

.manuItemsTwoCopyOne {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    h1 {
        font-size: 2rem;
        text-wrap: nowrap;
        color: #747686;
    }
    img {
        max-width: 75%;
    }
}

.manuItemsTwoCopyTwo {
    column-count: 2;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #747686;
}

@media only screen and (max-width: 768px) { 

    .heroManu {
     
    }
    
    .breadcrumbs {
        margin-left: 0%;
        grid-column-start: 2;
        grid-column-end: 4;
        h4 {
            padding: 8px 12px 8px;
            font-size: .75rem;
        }
    }

    .blurManuItem {
       
        margin-bottom: -15px;
    }

    .heroManuCont {
        
        grid-template-columns: repeat(4, 1fr);
        h1 {
            font-size: 1.75rem;
            padding-left: 0%;
            grid-column-start: 2;
            grid-column-end: 4;

        }
        img {
            margin-top: -2%;
            grid-row-start: 3;
            max-width: 40vw;
        }
        h3 {
            font-size: .8rem;
            
            padding-left: 0%;
            grid-column-start: 2;
            grid-column-end: 4;
        }
    }

    .companyIcon {
        min-width: 100px;
        min-height: 100px;
        margin-bottom: -50px;
        img {
            max-width: 80px;
        }
    }

    .manuCont {
        margin-top: 10%;
        width: 80vw;
        h1 {
            font-size: 2rem;
            padding-left: 16px;
        }
    }

    .featureManuItems {
        padding: 0px 16px;
        justify-content: space-between;
    }

    .contManuImg {
        width: 160px;
        height: 160px;
    }

    .manuContTwo {
        width: 80vw;
        margin-bottom: 10%;
    }

    .featureManuItemsTwo {
        max-width: 75vw;
    }

    .manuItemsTwoCopyOne {
        img {
            max-width: 55%;
        }
    }

    .manuItemsTwoCopyTwo {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media only screen and (max-width: 480px) {
    
    .breadcrumbs {
        h4 {
            padding: 10px 16px 10px;
            font-size: 1rem;
        }
    }

    .blurManuItem {
       
    }

    .heroManu {
        
    }

    .heroManuCont {
        
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0% 10%;
        gap: 15px;
        img {
            display: none;
        }
        h1 {
            font-size: 1.5rem;
            align-self: flex-start;
        }
        h3 {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    .manuCont {
        margin-top: 20%;
        h1 {
            font-size: 1.75rem;
            padding-left: 0px;
        }
    }

    .featureManuItems {
        padding: 0px 0px;
    }

    .manuItemDisplay {
        gap: 16px;
        margin-bottom: 6%;
    }

    .featureManuItemsTwo {
        max-width: 80vw;
    }

    .manuItemsTwoCopyOne {
        flex-direction: column;
    }

    .manuContTwo {
        margin-bottom: 20%;
    }

    .manuItemsTwoCopyOne {
        align-items: flex-start;
        gap: 16px;
        h1 {
            font-size: 1.75rem;
            text-wrap: wrap;
        }
        img {
            max-width: 100%;
        }
    }

    .manuItemsTwoCopyTwo {
        column-count: 1;
    }
}