/*****************************************************
** Navigation styles
**
** Anything related to general site navigation changes
*****************************************************/

// Styling for MPFDM defaut header
//
#main-menu {
    justify-content: end;
}


// Override for light navbar toggler
//
#wrapper-navbar {
    .navbar-toggler-light {
        .navbar-toggler {
            border-color: rgba(255, 255, 255, .7);
        }
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%28255, 255, 255%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
    }
}


// Normalization and readability for mobile menu
//
#main-menu { 
	
    li {

        a {
            font-size: 1.25rem;
            color: $dark;
        }

    }
    
} 

.navbar-nav{
    flex-direction: column;
}
.offcanvas-body{
        overflow-y: unset;
}
#mega-menu-primary{
    font-family: figtree, sans-serif;
font-weight: 800;
font-style: normal;
}
/*****************************************************
**
** 768+
**
*****************************************************/
@include media-breakpoint-up(md) 
{
    //
}


/*****************************************************
**
** 992+
**
*****************************************************/
@include media-breakpoint-up(lg) 
{

    // Spacing updates for desktop nav
    //

    .navbar-nav{
    flex-direction: row;
}
    #main-menu {
        justify-content: space-between;
        padding: 0 0 0 2rem;

        .nav-item {
            margin-right: 30px;
        }
    }

    // Increase font size, and make submenus a bit prettier
    //
    #main-menu { 
        
        li {

            .dropdown-menu {
                box-shadow: 0 0 6px rgba(0, 0, 0, .2);
                
                a:active {
                    color: white;
                }
                .nav-item:not(:last-child) {
                    margin-right: 0;
                }
                a { 
                    font-size: 1rem;
                    min-width: 200px;
                }            
            }
        }
    }

}


/*****************************************************
**
** 1200+
**
*****************************************************/
@include media-breakpoint-up(xl) 
{
    // Increase menu padding
    //
    #main-menu {
        padding: 0 2rem;
    }
}


/*****************************************************
**
** 1400+
**
*****************************************************/
@include media-breakpoint-up(xxl) 
{
    // Increase menu padding
    //
    #main-menu {
        padding: 0 3rem;
    }
}


/*****************************************************
**
** 1600+
**
*****************************************************/
@media all and (min-width: 1600px)
{
    // Increase font size and padding
    //
    #main-menu {
        padding: 0 4rem;

        .nav-link {
            font-size: 1.25rem;
        }
    }
}