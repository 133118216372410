/*****************************************************
** Button styles
**
** Anything to override or extend Bootstrap's buttons
*****************************************************/

// Button Icon class for pre and post fixed icon buttons
//
.btn-icon {
	display: flex;
	align-items: center;
	
    .phone-link-content {
        display: flex;
        align-items: center;
    }

    i {
        margin-right: 1rem;
        font-size: 1.25em;
    }
    .postfix-icon {
        margin-left: 1rem;
        margin-right: 0;
    }
}

/*****************************************************
**
** 768+
**
*****************************************************/
@include media-breakpoint-up(md) 
{
    //
}


/*****************************************************
**
** 992+
**
*****************************************************/
@include media-breakpoint-up(lg) 
{
    //
}


/*****************************************************
**
** 1200+
**
*****************************************************/
@include media-breakpoint-up(xl) 
{
    //
}


/*****************************************************
**
** 1400+
**
*****************************************************/
@include media-breakpoint-up(xxl) 
{
    //
}